<template>
  <div>
    <section>
      <Hero>
        <div class="container">
          <HeadText :titletext="'Mein Zweitmarkt'" :subtext="'Ihr Handelsplatz für geschlossene Fondsbeteiligungen'"></HeadText>
        </div>
        <div v-if="hasToken" class="container bg-light">
          <div class="alert alert-warning" role="alert">
            <p>Sie sind angemeldet. Nutzen Sie bitte die Seite <router-link to="/changepassword" class="nav-link">Passwort ändern</router-link></p>
          </div>
        </div>
        <div v-else-if="error" class="container bg-light">
          <div class="alert alert-danger" role="alert">
            <p>Es ist ein Fehler aufgetreten.</p>
            <p class="fs-7">{{ error }}</p>
          </div>
        </div>
        <div v-else>
          <div class="container bg-light">
            <h3 class="text-left pt-3">Sie möchten ein neues Passwort anfordern?</h3>
            <div class="row pt-3 pl-3 pr-3" v-if="submitSuccess">
              <div class="text-left w-100">
                <b-alert show variant="success" dismissible>
                  <p>
                    Ihr neues Passwort wird Ihnen demnächst zugesendet. 
                  </p>
                  <p>
                    Falls Sie in ein paar Minuten noch keine E-Mail bekommen haben, überprüfen Sie bitte Ihren Spam-Ordner.
                  </p>
                </b-alert>
              </div>
            </div>
            <div class="row justify-content-around pt-3 pb-3">
              <div class="col-sm-12 text-left">
                <p>
                  Bitte geben Sie Ihren Login-Namen unten ein und klicken Sie auf "Passwort zusenden". Ihnen wird ein 
                  Link an die von Ihnen hinterlegte E-Mail-Adresse geschickt. Aktivieren Sie den Link und erhalten Sie 
                  Zugang zu Ihrem Benutzerkonto.
                </p>
                <p>
                  Falls Sie mehrere E-Mails erhalten haben, benutzen Sie stets den Link in der neuesten E-Mail. Ältere 
                  Links sind ungültig.
                </p>
                <p class="text-left w-100" v-if="warning">
                  <b-alert show variant="warning">
                    {{ warning }}
                  </b-alert>
                </p>
                <b-form>
                  <b-form-group
                      id="input-group-1"
                      label="Login (E-Mail):"
                      label-for="input-1"
                  >
                    <b-form-input
                        id="input-1"
                        v-model="form.username"
                        type="text"
                        required
                        class="w-50"
                    ></b-form-input>
                  </b-form-group>
                  
                  <b-button id="reset-password-form-submit" 
                            variant="default" 
                            :pressed.sync="isWaiting" 
                            @click.once="onSubmit" 
                            :disabled="submitTriggered">
                    <span class="pr-2" v-if="isWaiting"><b-spinner small v-if="isWaiting"></b-spinner></span><span>Passwort zusenden</span>
                  </b-button>
                  
                </b-form>
              </div>
            </div>
          </div>
        </div>
      </Hero>
    </section>
  </div>
</template>

<script>
import Hero from "@/components/HeroPicture";
import HeadText from "@/components/HeadText";
import axios from "axios";

export default {
  name: "ResetPassword",
  components: {Hero,HeadText},
  metaInfo() {
    return {
      title: this.getClientConfig('name') + ' | ' + this.getClientConfig('title'),
      meta: [
        {
          name: 'description',
          content: 'Der Marktplatz für geschlossene Fonds. Wir bieten freien und institutionellen Vertriebsgesellschaften das führende Service- und Plattformangebot für die Auswahl, den Vertrieb, die Abwicklung und die Bestandsverwaltung geschlossener Fonds in Deutschland und Österreich.',
        },
      ],
    }
  },
  data: () => ({
    warning: null,
    error: null,
    form: {
      username: null,
    },
    submitTriggered: false,
    isWaiting: false,
    submitSuccess: false,
  }),
  computed: {
    hasToken() {
      return this.$store.getters["auth/hasToken"];
    },
  },
  methods: {
    getClientConfig(path) {
      return this.$store.getters['clientConfig/config'](path)
    },
    onSubmit(event) {
      this.error = null;
      this.warning = null;
      this.submitTriggered = true;
      event.preventDefault();
      var config = {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      }
      this.isWaiting = true;
      const payload = JSON.stringify( {login: this.form.username, mt_id: 121} ); // Mailtemplate-ID, "Passwort-Reset (Vermittlerportal)"
      axios.post( this.$store.getters.getAllEnvis.API_PASSWORD_RESETREQUEST.replace('{subsite}',this.$store.getters["getSubsite"]),
          payload, config )
          .then( () => {
            this.submitSuccess = true;
            this.isWaiting = false;
          })
          .catch(error => {
            console.log( error );
            this.isWaiting = false;
            if ( error.response.status === 400 ) {
              this.warning = 'Fehlerhafte Eingabe.';  
            }
            else if ( error.response.status === 404 ) {
              this.warning = 'Unbekannter Benutzername / E-Mail.';
            }
            else if ( error.response.status === 409 ) {
              this.warning = 'Es ist keine E-Mail-Adresse hinterlegt. Bitte wenden Sie sich an Ihren Betreuer.';
            }
            else if ( error.response.status === 502 ) {
              this.warning = 'Es konnte keine E-Mail versendet werden. Falls das wiederholt auftritt wenden Sie sich bitte an Ihren Betreuer.';
            }
            else {
              this.error = error;
            }
          })
    },
  }
}
</script>

<style scoped>

</style>